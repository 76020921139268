// App main
import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'swiper/css';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';

import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/nb';

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import jsonp from 'jsonp';
import rellax from 'rellax';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import Swiper from 'swiper';
import MiniMasonry from 'minimasonry';

dayjs.extend(localizedFormat);
dayjs.locale('nb');

Alpine.plugin(intersect);
mapboxgl.accessToken = 'pk.eyJ1IjoibXVzdGFzaiIsImEiOiJjamd4b2JoaWowOTkwMnFyanI0dTZxM2kyIn0.TDd9V2W1Y7zGZbnMqlsUag';

window['dayjs'] = dayjs;

function easing(x) {
    return Math.sin((x * Math.PI) / 2);
}

// Execute async function
document.body.style.opacity = '1';
new rellax('.rellax', {
    center: true
});

document.documentElement.classList.remove('no-js');

const header = document.querySelector<HTMLElement>('.js--miniheader');
let scrollTop = 0;
let oldScrollTop = -1;
window.addEventListener('scroll', () => {
    scrollTop = window.scrollY;
});

const tickers = document.querySelectorAll<HTMLElement>('.ticker-on-scroll');
if (tickers.length && window.innerWidth >= 768) {
    let tickerScrollTop = 0;
    let tickerOldScrollTop = 0;
    let stop = false;
    let cur = 0;
    const dur = 400;
    let origin = 0;
    let dest = 0;
    let start = 0;
    let end = 0;
    let scrollTimeout = null;

    function tickerRender(now) {
        if (stop) {
            return;
        }
        if (now - start >= dur) {
            stop = true;
        }
        const percent = (now - start) / dur;
        const value = easing(percent);
        cur = origin + (dest - origin) * value;
        tickers.forEach(ticker => {
            if (ticker.classList.contains('ticker--reverse')) {
                ticker.style.transform = `translateX(-${110 - (cur % 100)}%)`;
            } else {
                ticker.style.transform = `translateX(-${(cur % 100) - 10}%)`;
            }
        });
        requestAnimationFrame(tickerRender);
    }

    function startRender(timestamp) {
        stop = false;
        start = timestamp;
        end = start + dur;
        origin = cur;
        const diff = Math.abs(tickerScrollTop - tickerOldScrollTop);
        dest += diff / 50;
        tickerOldScrollTop = tickerScrollTop;
        requestAnimationFrame(tickerRender);
    }

    window.addEventListener('scroll', () => {
        tickerScrollTop = window.scrollY;
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
            requestAnimationFrame(startRender);
        }, 1);
    });
}

function render() {
    requestAnimationFrame(render);
    if (scrollTop !== oldScrollTop) {
        if (scrollTop < 100) {
            header.classList.add('-translate-y-full');
        } else if (scrollTop < oldScrollTop) {
            header.classList.remove('-translate-y-full');
        } else {
            header.classList.add('-translate-y-full');
        }
        oldScrollTop = scrollTop;
    }
}

if (header) {
    requestAnimationFrame(render);
}

const alwaysScrollTickers = document.querySelectorAll('.ticker-always-scroll');
if (window.innerWidth < 768 || alwaysScrollTickers.length) {
    document.querySelectorAll('.js--ticker-container').forEach(container => {
        const width = container.children[0].scrollWidth;
        Array.apply([], container.children).forEach(ticker => {
            ticker.style.animationDuration = width * 2 + '0ms';
        });
    });
}

document.querySelectorAll('.js--expandable-header').forEach(header => {
    const expandable = header.nextElementSibling;
    const svg = header.querySelector('use');
    header.addEventListener('click', () => {
        if (header.classList.contains('md:js--expandable-off') && window.innerWidth >= 768) {
            return;
        }
        if (expandable.hasAttribute('style')) {
            expandable.removeAttribute('style');
            expandable.classList.remove('py-4');
            svg.setAttribute('href', '#icon-expand');
        } else {
            expandable.setAttribute('style', `max-height: ${expandable.scrollHeight + 32}px`);
            expandable.classList.add('py-4');
            svg.setAttribute('href', '#icon-collapse');
        }
    });
});

document.querySelectorAll('.js--filter').forEach(elm => {
    elm.addEventListener('click', event => {
        const target: Element = (<HTMLElement>event.target).closest('.tag');
        if (target) {
            if (target.classList.contains('active')) {
                elm.querySelector<HTMLElement>('.tag').click();
            } else {
                document.querySelector('.js--filter .active').classList.remove('active');
                target.classList.add('active');
                const cat = target.getAttribute('data-href');
                document.querySelectorAll('[data-cat]').forEach(elm => {
                    const elmCat = elm.getAttribute('data-cat').split(',');
                    if (cat === 'all' || elmCat.includes(cat)) {
                        elm.classList.remove('hidden');
                    } else {
                        elm.classList.add('hidden');
                    }
                });
            }
        }
    });
});

document.querySelectorAll('.js--menu-toggle').forEach(elm => {
    elm.addEventListener('click', () => {
        document.querySelector('.js--menu').classList.toggle('translate-x-full');
        document.body.classList.toggle('overflow-hidden');
    });
});

document.querySelector('.js--newsletter').addEventListener('submit', ev => {
    const form = <HTMLFormElement>ev.target;
    jsonp(
        form.action.replace('post?', 'post-json?') + '&EMAIL=' + form.email.value,
        {param: 'c'},
        (err, data) => {
            const feedback = document.querySelector('.js--newsletter-feedback');
            feedback.innerHTML = feedback.innerHTML.replace(/{email}/g, form.email.value);
            feedback.classList.remove('hidden');
            if (err || data.result !== 'success') {
                feedback.querySelector('.js--newsletter-error').classList.remove('hidden');
            } else {
                feedback.querySelector('.js--newsletter-success').classList.remove('hidden');
            }

            document.querySelector('.js--newsletter-close').addEventListener('click', ev => {
                document
                    .querySelectorAll(
                        '.js--newsletter-feedback, .js--newsletter-success, .js--newsletter-error'
                    )
                    .forEach(elm => {
                        elm.classList.add('hidden');
                    });
            });
        }
    );

    ev.preventDefault();
});

const warnings = document.querySelectorAll<HTMLElement>('.js--warning');
if (warnings.length) {
    warnings.forEach((warning, i) => {
        const span = warning.querySelector('span');
        const name = warning.getAttribute('data-name');
        warning.querySelector('button').addEventListener('click', () => {
            localStorage.setItem(`warning-${name}`, span.innerText);
            warning.classList.add('hidden');
        });
        if (localStorage.getItem(`warning-${name}`) !== span.innerText) {
            warning.classList.remove('hidden');
        }
    });
}

function initScrollLines() {
    const main = document.querySelector<HTMLElement>('.js--track-start');
    top = main.getBoundingClientRect().top + window.scrollY;
    const wrapper = document.querySelector<HTMLElement>('.js--track-wrapper');
    scrollHeight = main.offsetHeight;
    windowHeight = window.innerHeight;
    if (wrapper.classList.contains('resizable')) {
        wrapper.style.top = top + 'px';
        wrapper.style.height = scrollHeight + 'px';
        wrapper.classList.remove('hidden');
    }
    const viewbox = scrollLine.getAttribute('viewBox').split(' ');
    const scrollPath = scrollLine.querySelector('path');
    const timelineLength = scrollPath.getTotalLength();
    ratio = scrollLine.getBoundingClientRect().height / parseInt(viewbox[3]);
    scrollLine.style.strokeDashoffset = timelineLength + 'px';
    scrollLine.style.strokeDasharray = timelineLength + 'px';
}

const scrollLine = document.querySelector<HTMLElement>('.js--track');
let top = 0;
let scrollHeight = 0;
let windowHeight = 0;
let ratio = 0;

if (scrollLine) {
    initScrollLines();
    let resizeTO = null;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTO);
        resizeTO = setTimeout(() => {
            if (window.innerWidth >= 1280) {
                initScrollLines();
                oldScrollPos = -1;
            }
        }, 400);
    });
    const scrollSymbol = document.querySelector<HTMLElement>('.js--track-symbol');
    const scrollPath = scrollLine.querySelector('path');
    const timelineLength = scrollPath.getTotalLength();
    let dragging = false;
    if (scrollSymbol.classList.contains('draggable')) {
        scrollSymbol.addEventListener('mousedown', ev => {
            dragging = true;
        });

        document.addEventListener('mouseup', () => {
            dragging = false;
        });

        document.addEventListener('mousemove', ev => {
            if (dragging) {
                const perc = (ev.clientY - windowHeight * 0.05) / (windowHeight * 0.95);
                window.scrollTo(0, perc * scrollHeight);
            }
        });
    }

    let scrollPos = 1;
    let oldScrollPos = -1;
    document.addEventListener('scroll', () => {
        scrollPos = window.scrollY;
    });

    function timelineRender() {
        requestAnimationFrame(timelineRender);

        if (scrollPos !== oldScrollPos && scrollPos > 0) {
            const per = scrollPos / scrollHeight;
            const point = scrollPath.getPointAtLength(timelineLength - timelineLength * per);
            scrollSymbol.style.top = point.y * ratio + 'px';
            scrollSymbol.style.marginLeft = point.x * ratio + 'px';
            scrollLine.style.strokeDashoffset =
                Math.min(timelineLength * 2, timelineLength + timelineLength * per) + 'px';
            oldScrollPos = scrollPos;
        }
    }

    requestAnimationFrame(timelineRender);
}

function goToLoypekartItem(index) {
    const box = loypeSections[index].getBoundingClientRect();
    window.scrollTo({
        top: box.top + window.scrollY - window.innerHeight / 2 + box.height / 2,
        behavior: 'smooth'
    });
}

const loypePath = document.querySelector<HTMLElement>('.js--loypekart-path');
const loypeSections = Array.apply([], document.querySelectorAll('.css--item'));
let loypeSwiper: Swiper;
if (loypePath) {
    const infoBoxes = document.querySelectorAll<HTMLElement>('.js--loypekart-info');
    let currentSteg = 0;
    document.querySelectorAll<HTMLElement>('.js--loypekart-show-info').forEach(button => {
        button.addEventListener('click', () => {
            currentSteg = parseInt(button.getAttribute('data-id'));
            infoBoxes[currentSteg].classList.remove('hidden');
        });
    });
    document.querySelectorAll<HTMLElement>('.js--loypekart-close-info').forEach(button => {
        button.addEventListener('click', () => {
            infoBoxes[currentSteg].classList.add('hidden');
        });
    });
    document.querySelectorAll<HTMLElement>('.js--loypekart-prev-info').forEach(button => {
        button.addEventListener('click', () => {
            infoBoxes[currentSteg].classList.add('hidden');
            currentSteg--;
            infoBoxes[currentSteg].classList.remove('hidden');
            goToLoypekartItem(currentSteg);
        });
    });
    document.querySelectorAll<HTMLElement>('.js--loypekart-next-info').forEach(button => {
        button.addEventListener('click', () => {
            infoBoxes[currentSteg].classList.add('hidden');
            currentSteg++;
            infoBoxes[currentSteg].classList.remove('hidden');
            goToLoypekartItem(currentSteg);
        });
    });

    // Swiper
    document.querySelectorAll<HTMLElement>('.css--item img').forEach((elm, i) => {
        elm.addEventListener('click', () => {
            document.querySelector('.js--loypekart-swiper-wrapper').classList.remove('hidden');
            loypeSwiper.slideTo(i);
        });
    });
    document.querySelector('.js--loypekart-close-swiper').addEventListener('click', () => {
        document.querySelector('.js--loypekart-swiper-wrapper').classList.add('hidden');
    });

    const geojsons = window.hksGeojsons;
    const map = new mapboxgl.Map({
        container: document.querySelector('.js--mapbox'),
        style: 'mapbox://styles/holmenkollstafetten/ckz2s8377000514nrsmjf3zxi',
        center: geojsons[0][0],
        zoom: 12
    });
    const completeLine = [].concat.apply([], geojsons);

    function getDistanceFromLatLonInKm(latitude1, longitude1, latitude2, longitude2) {
        var p = 0.017453292519943295; //This is  Math.PI / 180
        var c = Math.cos;
        var a =
            0.5 -
            c((latitude2 - latitude1) * p) / 2 +
            (c(latitude1 * p) * c(latitude2 * p) * (1 - c((longitude2 - longitude1) * p))) / 2;
        var R = 6371; //  Earth distance in km so it will return the distance in km
        return 2 * R * Math.asin(Math.sqrt(a));
    }

    function getAllLines(startPoint, endPoint) {
        const distance = getDistanceFromLatLonInKm(
            endPoint[0],
            endPoint[1],
            startPoint[0],
            startPoint[1]
        );
        const speedFactor = Math.round(distance * 200);
        const diffX = endPoint[0] - startPoint[0];
        const diffY = endPoint[1] - startPoint[1];

        const sfX = diffX / speedFactor;
        const sfY = diffY / speedFactor;

        let i = 0;
        let j = 0;

        const lineCoordinates = [];

        while (Math.abs(i) < Math.abs(diffX) || Math.abs(j) < Math.abs(diffY)) {
            lineCoordinates.push([startPoint[0] + i, startPoint[1] + j]);

            if (Math.abs(i) < Math.abs(diffX)) {
                i += sfX;
            }

            if (Math.abs(j) < Math.abs(diffY)) {
                j += sfY;
            }
        }
        return lineCoordinates;
    }

    const allPoints = [];
    geojsons.forEach(geojson => {
        let tmp = [];
        geojson.forEach((coord, i) => {
            if (i === 0) {
                return;
            }
            tmp = tmp.concat(getAllLines(geojson[i - 1], coord));
        });
        allPoints.push(tmp);
    });
    const features = [];
    map.on('load', () => {
        map.addLayer({
            id: 'whole-line',
            type: 'line',
            source: {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'LineString',
                                coordinates: completeLine
                            }
                        }
                    ]
                }
            },
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#947E52',
                'line-width': 2
            }
        });
        geojsons.forEach((geojson, i) => {
            const el = document.createElement('div');
            el.className = 'css--marker';
            el.style.background = window.hksColor;
            el.innerHTML = `<span>${i + 1}</span>`;
            new mapboxgl.Marker(el).setLngLat(geojson[0]).addTo(map);

            features.push({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                ]
            });
            map.addLayer({
                id: `line${i}`,
                type: 'line',
                source: {
                    type: 'geojson',
                    data: features[i]
                },
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': window.hksColor,
                    'line-width': 2
                }
            });
        });
    });

    const tops = loypeSections.map(
        s =>
            s.getBoundingClientRect().top +
            window.scrollY -
            window.innerHeight / 2 +
            s.getBoundingClientRect().height / 2
    );

    function moveMap() {
        const scrollTop = window.scrollY;
        const next = tops.find(t => t > scrollTop);
        const index = tops.indexOf(next);
        if (index < 1) {
            return;
        }
        const prev = tops[index - 1];
        const diff = next - prev;
        const perc = (scrollTop - prev) / diff;
        const points = allPoints[index - 1];
        features[index - 1].features[0].geometry.coordinates = [];
        let i = 0;
        for (i = 0; i < points.length * perc; i++) {
            features[index - 1].features[0].geometry.coordinates.push(points[i]);
        }
        map.getSource(`line${index - 1}`).setData(features[index - 1]);
        map.setCenter(points[i]);

        // sørg for å tømme den neste linja
        if (features[index]) {
            features[index].features[0].geometry.coordinates = [];
            map.getSource(`line${index}`).setData(features[index]);
        }

        // og fyll opp den forrige
        if (index > 1) {
            features[index - 2].features[0].geometry.coordinates = geojsons[index - 2];
            map.getSource(`line${index - 2}`).setData(features[index - 2]);
        }
    }

    document.addEventListener('scroll', () => {
        requestAnimationFrame(moveMap);
    });

    const mapWrapper = document.querySelector('.css--mapbox-wrapper');
    const controls = new mapboxgl.NavigationControl();
    const btn = mapWrapper.querySelector('.js--mapbox-toggle');

    btn.addEventListener('click', () => {
        if (mapWrapper.classList.contains('large')) {
            btn.querySelector('use').setAttribute('href', '#icon-maximize');
            map.removeControl(controls);
            map.setPaintProperty('whole-line', 'line-width', 2);
            for (let i = 0; i < geojsons.length; i++) {
                map.setPaintProperty(`line${i}`, 'line-width', 2);
            }
        } else {
            btn.querySelector('use').setAttribute('href', '#icon-minimize');
            map.addControl(controls);
            map.setPaintProperty('whole-line', 'line-width', 4);
            for (let i = 0; i < geojsons.length; i++) {
                map.setPaintProperty(`line${i}`, 'line-width', 4);
            }
        }
        mapWrapper.classList.toggle('large');
        map.resize();
    });
}

const contactMap = document.querySelector('.js--contact-map');
if (contactMap) {
    const latlong = window.hksContactLongLat;
    const map = new mapboxgl.Map({
        container: contactMap,
        style: 'mapbox://styles/holmenkollstafetten/ckz2s8377000514nrsmjf3zxi',
        center: latlong,
        zoom: 12
    });
    const el = document.createElement('div');
    el.className = 'w-12 h-12 xl:w-20 xl:h-20';
    const symbol = document.querySelector('.js--map-symbol');
    el.appendChild(symbol);
    new mapboxgl.Marker(el).setLngLat(latlong).addTo(map);
}

document.querySelectorAll<HTMLElement>('.swiper').forEach(elm => {
    const isLoypekart = elm.classList.contains('js--loypekart-swiper');

    const tmp = new Swiper(elm, {
        slidesPerView: window.innerWidth < 1024 ? 1.2 : 1.3,
        centeredSlides: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        on: {
            slideChange: swiper => {
                if (isLoypekart) {
                    goToLoypekartItem(swiper.activeIndex);
                }
            }
        }
    });
    if (isLoypekart) {
        loypeSwiper = tmp;
    }
});

if (location.hash.length > 2) {
    const hash = decodeURI(location.hash.replace('#', ''));
    const div = document.querySelector<HTMLElement>(`[id="${hash}`);
    if (div) {
        const target = div.querySelector<HTMLElement>('.js--expandable-header');
        if (target) {
            target.click();
        }
    }
}

const masonryElm = document.querySelector<HTMLElement>('.js--masonry');
if (masonryElm) {
    const masonry = new MiniMasonry({
        container: masonryElm,
        baseWidth: window.innerWidth / (window.innerWidth > 1280 ? 5 : 4)
    });
    let layoutTO;
    document.addEventListener('lazyloaded', () => {
        document.querySelectorAll<HTMLElement>('.js--masonry .bottom-0').forEach(elm => {
            elm.classList.remove('bottom-0');
        });
        clearTimeout(layoutTO);
        layoutTO = setTimeout(() => {
            masonry.layout();
        }, 100);
    });

    const btn = document.querySelector<HTMLElement>('.js--loadfeed');
    if (btn) {
        btn.addEventListener('click', () => {
            const next = hksFeed.splice(0, 20);
            let html = '';
            next.forEach(item => {
                html += `
                    <div class="absolute min-h-[100px] bottom-0">
                        <img data-src="${item.thumbnail}" alt="Bilde fra Instagram" class="lazyload w-full"/>
                        <a href="https://www.instagram.com/p/${item.shortcode}/" target="_blank" class="${!isNaN(item.shortcode) && 'hidden'} rounded bg-white shadow absolute right-4 top-4 p-1 hover:bg-gold hover:text-white">
                            <svg class="w-5 h-5 mx-auto block relative">
                                <use href="#icon-instagram"/>
                            </svg>
                        </a>
                    </div>`;
            });
            masonryElm.innerHTML += html;
            if (hksFeed.length === 0) {
                btn.classList.add('hidden');
            }
        });
    }
}

window['app'] = {
    initStatistikk() {
        return {
            firma: [],
            statistikk: [],
            async hentFirma() {
                const {data} = (await (await fetch('/api/statistikk/firma')).json());
                this.firma = data.filter(item => item.lag).sort((a, b) => b.lag - a.lag);
            },
            async hentLeaderboard() {
                const {data} = (await (await fetch('/api/statistikk/leaderboard')).json());
                this.statistikk = data;
                if (this.statistikk.length > 0) {
                    await this.amchart();
                }
            },
            async amchart() {
                const test = this.$refs.chart;
                const am5 = await import('@amcharts/amcharts5'),
                    am5xy = await import('@amcharts/amcharts5/xy');

                am5.ready(() => {
                    const root = am5.Root.new(test);

                    const chart = root.container.children.push(
                        am5xy.XYChart.new(root, {
                            panX: false,
                            panY: false,
                            wheelX: 'panY',
                            wheelY: 'zoomY',
                            layout: root.verticalLayout
                        })
                    );

                    root.durationFormatter.setAll({
                        baseUnit: 'second',
                        durationFormat: 'mm:ss',
                        durationFields: ['valueX', 'axisX']
                    });

                    chart.set(
                        'scrollbarY',
                        am5.Scrollbar.new(root, {
                            orientation: 'vertical'
                        })
                    );

                    this.statistikk.sort((a, b) => {
                        return a.etappe1 + a.etappe2 + a.etappe3 + a.etappe4 + a.etappe5 >
                        b.etappe1 + b.etappe2 + b.etappe3 + b.etappe4 + b.etappe5
                            ? -1
                            : 1;
                    });

                    const yAxis = chart.yAxes.push(
                        am5xy.CategoryAxis.new(root, {
                            categoryField: 'title',
                            renderer: am5xy.AxisRendererY.new(root, {}),
                            tooltip: am5.Tooltip.new(root, {})
                        })
                    );

                    yAxis.data.setAll(this.statistikk);

                    const xAxis = chart.xAxes.push(
                        am5xy.DurationAxis.new(root, {
                            baseUnit: 'second',
                            min: 0,
                            renderer: am5xy.AxisRendererX.new(root, {})
                        })
                    );

                    chart.get('colors')
                        .set('colors', [
                            am5.color(0x635230),
                            am5.color(0x856e41),
                            am5.color(0x947e52),
                            am5.color(0xd7cbb4),
                            am5.color(0xd9a729)
                        ]);

                    const legend = chart.children.push(
                        am5.Legend.new(root, {
                            centerX: am5.p50,
                            x: am5.p50
                        })
                    );

                    const makeSeries = (name, fieldName) => {
                        var series = chart.series.push(
                            am5xy.ColumnSeries.new(root, {
                                name: name,
                                stacked: true,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                baseAxis: yAxis,
                                valueXField: fieldName,
                                categoryYField: 'title',
                                categoryXField: ''
                            })
                        );

                        series.columns.template.setAll({
                            tooltipText: '{name}, {categoryY}: {valueX}',
                            tooltipY: am5.percent(90)
                        });
                        series.data.setAll(this.statistikk);

                        series.appear();

                        series.bullets.push(function () {
                            return am5.Bullet.new(root, {
                                sprite: am5.Label.new(root, {
                                    text: '{valueX}',
                                    fill: root.interfaceColors.get('alternativeText'),
                                    centerY: am5.p50,
                                    centerX: am5.p50,
                                    populateText: true
                                })
                            });
                        });

                        legend.data.push(series);
                    }

                    makeSeries('Etappe 1', 'etappe1');
                    makeSeries('Etappe 2', 'etappe2');
                    makeSeries('Etappe 3', 'etappe3');
                    makeSeries('Etappe 4', 'etappe4');
                    makeSeries('Etappe 5', 'etappe5');

                    chart.appear(1000, 100);
                });
            }
        }
    },
    showVideo(start: string = dayjs().toString(), end: string = null) {
        return {
            show: false,
            init() {
                this.show = this.checkShow || window.location.hash === '#testAvspiller';
                if (this.show) return;
                // console.log('check for updates');
                setInterval(() => {
                    this.show = this.checkShow;
                }, 1000 * 15)
            },
            get checkShow() {
                // console.log('check show');
                if (end) {
                    return dayjs().isAfter(start) && dayjs().isBefore(end)
                }
                return dayjs().isAfter(start)
            }
        }
    }
}
Alpine.start()
